import './cta.css';

const ProjectLink = () => {
  

  return (
    <div className='siteHub__cta--home'>
      <div className='siteHub__cta-content'>
        <h2 style={{ color: '#FFFFFF' }}>We always deliver</h2>
        <h4 style={{ color: '#FFFFFF' }}>Your money back if we don't deliver at least 5 quote ready customers in the first 30 days</h4>
      </div>
      <div className='siteHub__cta-btn--home '>
        <a href="/Features/projectManagement.html">
          <button type='button'>Start today</button>
        </a>
      </div>
    </div>
  );
};

export default ProjectLink;




