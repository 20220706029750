import { FaLongArrowAltRight } from 'react-icons/fa'
import './MslIntro.css'

const MslIntro = () => {
  return (
    <div className='msl__intro'>
      <h2>Competence, Creativity & Practice</h2>
      <p>Delivering exceptional painting and decorating services across London and Kent, we have proudly upheld a tradition of quality for X years.</p>
      <a href="/mslDecorating">
        <button>We'd love to hear about your upcoming project <FaLongArrowAltRight/></button>
      </a>
    </div>
  )
}

export default MslIntro