import React, { useState } from 'react';
import axios from 'axios';
import './HomeContact.css';

const HomeContact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    additionalInfo: '',
  });

  const [lastSubmitted, setLastSubmitted] = useState(Date.now() - 60000);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const now = Date.now();

    if (now - lastSubmitted >= 60000) { // checks if it's been a minute since last submission
      try {
        setLastSubmitted(now);
        // Replace with your own API endpoint
        await axios.post('/api/submitDetails', formData); 
        alert('Form submitted successfully');
        // Clear the form
        setFormData({
          fullName: '',
          email: '',
          phoneNumber: '',
          companyName: '',
          additionalInfo: '',
        });
      } catch (error) {
        console.error('There was an error submitting the form!', error);
      }
    } else {
      alert('Please wait for a minute before submitting again.');
    }
  };

  return (
    <div className='home__contact'>
      <div className="home__contact__bg"></div>
      <div className="home__contact__text">
        <h2>Ready to transform your business?</h2>
        <form onSubmit={handleSubmit}>
          <div className="form__option">
            <p>Your full name</p>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your email</p>
            <input type="text" name="email" value={formData.email} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your phone number</p>
            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your company name</p>
            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Any further information?</p>
            <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default HomeContact;
