import './Header.css'
const Header = () => {
  return (
    <div className='msl__header center__flex'>
      <div className="header__text">
        <h1>MSL Decorating</h1>
        <p>Painting & decorating services covering Kent & London</p>
        <a href="/mslDecorating">
          <button>Arrange a quote today</button>
        </a>
      </div>
    </div>
  )
}

export default Header