import React, {  useState, useRef, useEffect } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './HomeNav.css';
import logo from '../../Assets/logoRec.png'
const HomeNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [viewFeatures, setViewFeatures] = useState(false);
  const featureRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewFeatures && // Check if viewFeatures is true
        featureRef.current && // Check if featureRef is defined
        !featureRef.current.contains(event.target) && // Click is outside of featureRef
        event.target.textContent !== 'Features' // Click is not on "Features" text
      ) {
        setViewFeatures(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [viewFeatures]);


  return (
    <header className='nav__header' style={{height: `${viewFeatures ? '100vh' : 'unset'}` }}>
    <nav className='siteHub__nav'>

      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <a href="/">
            <img src={logo} alt="siteHUB logo" />
          </a>
        </div>
        <div className='gpt3__navbar-links_container'>
          <p><a className='span__hover' href="/#home">Home</a></p>
          <p onClick={()=>setViewFeatures(true)}>Features</p>
          <p><a className='span__hover' href="/pricing">pricing</a></p>
          <p><a className='span__hover' href="/">demo</a></p>
          <p><a className='span__hover' href="/contact">Contact</a></p>
        </div>
      </div>

      <div className='gpt3__navbar-sign'>
        <a href="/contact">
          <button  type='button'>Contact</button>
        </a>
        <div className="toggle__menu">

        {toggleMenu
          ? <RiCloseLine  size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
        }
        </div>
      </div>
    </nav>
        {viewFeatures && (
          <div className="feature__cont" ref={featureRef}>
            <div className='feature__list'>
              <h2>Management</h2>
              <ul>
                <li><a href="/Features/projectManagement.html">Project management</a></li>
                <li><a href="/Features/addingProjects.html">Adding projects</a></li>
                <li><a href="/Features/siteManagement.html">Site management</a></li>
                <li><a href="/Features/Calender.html">Calender</a></li>
                <li><a href="/Features/Documents.html">Documents</a></li>
                <li>Analysis</li>
              </ul>
            </div>
            <div className='feature__list'>
              <h2>Teams</h2>
              <ul>
                <li><a href="/Features/Contacts.html">Contacts</a></li>
                <li><a href="/Features/TimeSheets.html">Time sheets</a></li>
                <li><a href="/Features/Payroll.html">Payroll</a></li>
                <li><a href="/Features/HealthAndSafety.html">Health and safety</a></li>
                <li><a href="/Features/IncidentReporting.html">Incident reporting</a></li>
              </ul>
            </div>
            <div className='feature__list'>
              <h2>Inventory</h2>
              <ul>
                <li>Databases</li>
                <li>Purchases</li>
                <li>Tracking</li>
              </ul>
            </div>
            <div className='feature__list'>
              <h2>Customer portal</h2>
              <ul>
                <li>Adding customers</li>
                <li>Get paid with siteHUB</li>
                <li>Managing relationships</li>
              </ul>
            </div>
          </div>
        )}
        {toggleMenu && (
          <div className='siteHub__navbar-menu '>
            <div className='siteHub__navbar-menu_container scale-up-center'>
              <p><a className='span__hover' href="/#home">Home</a></p>
              <p><a className='span__hover' href="/Features/Features.html">Features</a></p>
              <p><a className='span__hover' href="/pricing">pricing</a></p>
              <p><a className='span__hover' href="/">demo</a></p>
              <p><a className='span__hover' href="/contact">Contact</a></p>
            </div>
            <div className='siteHUb-menu_container-links-sign scale-up-center'>
            <a href="https://web.sitehubapp.com/">
                <p>Contact Us</p>
                <button type='button'><FaLongArrowAltRight /></button>
              </a>
            </div>
          </div>
        )}


    </header>
  )
}

export default HomeNav