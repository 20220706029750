import React from 'react'
import './footer.css'
import logo from '../../Assets/logoRec.png'
const Footer = () => {

  return (
    <div className='siteHub section__padding' id='footer'>
      <div className='siteHub-links'>
        <div className='siteHub-links_logo'>
          <img src={logo} alt="" />
          <p>86-90, Paul Street, London, EC2A 4NE UK. All Rights Reserved</p>
        </div>
        <div className='siteHub-links_div'>
          <h4>Links</h4>
          <p>Facebook</p>
          <p>Twitter</p>
          <p>Instagram</p>
          <p>LinkedIn</p>
        </div>

        <div className='siteHub-links_div'>
          <h4>Company</h4>
          <a href="/legal/termsOfService">
          <p >Terms of service</p>
          </a>
          <a href="/legal/privacy">
            <p >Privacy Policy</p>
          </a>
          <a href="/contact">
            <p>Contact</p>
          </a>
        </div>

        <div className='siteHub-links_div'>
          <h4>Get in Touch</h4>
          <p>86-90, Paul Street, London, EC2A 4NE</p>
          <p>United Kingdom - 02033764233</p>
          <p>info@sitehubapp.com</p>
        </div>
      </div>
      <div className='siteHub-copyright'>
        <p>2023 siteHUB. ALL RIGHTS RESERVED</p>
        <p>Part of the consultAI family</p>
      </div>
    </div>
  )
}

export default Footer