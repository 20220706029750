import './MeetTeam.css'
import logo from './logo.png'
const MeetTeam = () => {
  return (
    <div className='meet__team'>
      <div className="services__overlay"></div>
        <div className="meet__text">
          <h2>Established by Manjeet Lar</h2>
          <p>We offer premier Painting & Decorating Services in Kent and London. We specialize in both interior and exterior projects, providing a unique flair to each endeavor, with a focus on high-end works. With a team that is not only fully qualified but also insured, we ensure to deliver services that embody excellence and reliability.</p>
          <a href="/mslDecorating">
            <button>
              Meet the Team
            </button>
          </a>
        </div>
      <div className="meet__logo">
        <img src={logo} alt="" />
      </div>
    </div>
  )
}

export default MeetTeam