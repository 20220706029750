import React from 'react';
import { FaPaintBrush, FaDollarSign, FaSearch, FaThumbsUp } from 'react-icons/fa';
import './FeatureInfoSection.css';

const FeatureInfoSection = () => {
  return (
    <div className='feature__info' id='wsiteHUB'>
      <div className="feature__info--container">
        <span><FaPaintBrush/></span>
        <h2>Web Creation</h2>
        <p>Our dedicated team transforms your unique vision into a visually stunning and functional website that not only captivates visitors but also effectively converts leads into loyal customers, supporting your business goals and boosting your online presence.</p>
      </div>
      <div className="feature__info--container">
        <span><FaDollarSign/></span>
        <h2>Paid Advertising</h2>
        <p>Unleash the power of meticulously managed and highly targeted paid advertising campaigns designed to drive qualified traffic to your site. Experience increased conversions and maximized return on investment as we navigate and optimize your paid ads landscape, reaching your audience with precision and relevance.</p>
      </div>
      <div className="feature__info--container">
        <span><FaSearch/></span>
        <h2>SEO Optimization</h2>
        <p>Propel your website to the top of Google's search results with our strategic SEO practices. By blending cutting-edge techniques and a deep understanding of your industry's landscape, we help drive organic traffic to your site, enhancing visibility and establishing your brand as an authority in the online space.</p>
      </div>
      <div className="feature__info--container">
        <span><FaThumbsUp/></span>
        <h2>Social Media Management</h2>
        <p>Engage with your audience like never before with our comprehensive Social Media Management services. We create and curate compelling content, develop and execute strategic campaigns, and analyze data to continually refine your social media presence. Stay connected with your clients, build a loyal community, and amplify your brand’s voice across multiple platforms with our expert assistance.</p>
      </div>
    </div>
  )
}

export default FeatureInfoSection;
