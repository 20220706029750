import Action from './Components/Action/Action'
import Header from './Components/Header/Header'
import HomeContact from './Components/HomeContact/HomeContact'
import InfoSec from './Components/InfoSec/InfoSec'
import MslIntro from './Components/Intro/MslIntro'
import MeetTeam from './Components/MeetTeam/MeetTeam'
import Footer from './Components/MslFooter/Footer'
import HomeNav from './Components/Nav/HomeNav'
import Reviews from './Components/Reviews/Reviews'
import Services from './Components/Services/Services'
import './MSLhome.css'

const MSLhome = () => {
  return (
    <div>
      <HomeNav />
      <Header />
      <MslIntro />
      <Services />
      <InfoSec />
      <MeetTeam />
      <Reviews />
      <Action />
      <HomeContact />
      <Footer />
    </div>
  )
}

export default MSLhome