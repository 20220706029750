import {  FaArrowRight } from 'react-icons/fa'
import { useEffect, useState, useRef } from 'react';
import './Header.css'

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        setIsScrolled(true);
      }, 550); // 550 milliseconds (0.55 seconds) delay
  
      const handleScroll = () => {
        const headerElement = headerRef.current;
        if (headerElement) {
          setIsScrolled(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  



  return (
    <div className={`siteHub__header center__flex ${isScrolled ? 'scale-up-center-home view__page' : 'none__view'}`} ref={headerRef} id='home'>
      <div className="siteHub__header--backdrop">
        <div className="siteHub__home--text">
        <h1>Strategic Marketing for Construction Firms</h1>
        <h3>Don’t let opportunities slip through the cracks! Construction firms lose significant revenue daily due to ineffective branding and marketing strategies. Isn’t it time to uplift and revolutionize your business?</h3>

          <div className="siteHub__home--links">
            <a href="/features">
              <h5>Contact us today to get started</h5>
              <button><FaArrowRight/></button>
            </a>
            <div className="siteHub__home--join">
              join 100s of companies elevating their businesses
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header