import { FaHome, FaPaintRoller } from 'react-icons/fa'; // Importing FaPaintRoller for exterior works
import './Services.css';

const Services = () => {
  return (
    <div className='services__link'>
      <div className="services__section">
          <div className="services__overlay services__overlay__one"/>
          <div className="services__text">
            <span><FaHome/></span>
            <h2>Exterior Works</h2>
            <p>Our expert tradesmen specialize in exterior painting and decorating, delivering services that not only enhance the aesthetic appeal of your home but also protect it from the elements. Operating throughout London, we've successfully revitalized numerous exteriors, rendering them both beautiful and durable.</p>
            <a href="/mslDecorating">
              <button>
                view more
              </button>
            </a>
          </div>
      </div>
      <div className="services__section">
      <div className="services__overlay services__overlay__two"/>
        <div className="services__text">
          <span><FaPaintRoller/></span>
          <h2>Interior Works</h2>
          <p>Skilled tradesmen with extensive experience lead our residential painting and decorating services, ensuring a professional, efficient, and exceptionally high-standard outcome for every project. Based in London, we have a long history of helping clients elevate the beauty of their homes, apartments, and houses.</p>
          <a href="/mslDecorating">
            <button>
              view more
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Services;
