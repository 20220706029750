import './Reviews.css'

const Reviews = () => {
  return (
    <div className='reviews__section'>
      <div className="review__item">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad laborum inventore voluptatum facere vitae dolores, delectus accusantium quos illo rerum aut ipsa iste, pariatur nulla soluta quia, eos sequi officiis numquam aliquam. In assumenda aliquid provident! Repellendus praesentium accusantium vel!</p>
        <h2>Customer name</h2>
      </div>
      <div className="review__item">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad laborum inventore voluptatum facere vitae dolores, delectus accusantium quos illo rerum aut ipsa iste, pariatur nulla soluta quia, eos sequi officiis numquam aliquam. In assumenda aliquid provident! Repellendus praesentium accusantium vel!</p>
        <h2>Customer name</h2>
      </div>
      <div className="review__item">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad laborum inventore voluptatum facere vitae dolores, delectus accusantium quos illo rerum aut ipsa iste, pariatur nulla soluta quia, eos sequi officiis numquam aliquam. In assumenda aliquid provident! Repellendus praesentium accusantium vel!</p>
        <h2>Customer name</h2>
      </div>
    </div>
  )
}

export default Reviews