import './Action.css'

const Action = () => {
  return (
    <div className='service__section'>
      <div className="service__image"></div>
      <div className="service__text">
        <h2>Transform Your Spaces</h2>
        <p>We specialize in a broad spectrum of painting and decorating services, including the meticulous application of designer wallpapers. Our seasoned team ensures a seamless, precise, and luxurious finish for every project, whether it involves painting, wallpaper installation, or general decorating. If you have a vision for your space, connect with our experts today for professional advice and assistance.</p>
        <a href="/mslDecorating">
          <button>
            Get a Quote
          </button>
        </a>
      </div>
    </div>
  )
}

export default Action