import Footer from './HomeComponents/footer/Footer'
import './Home.css'
import FeatureInfoSection from './HomeComponents/FeatureInfoSection/FeatureInfoSection'
import Header from './HomeComponents/Header/Header'
import Nav from './HomeComponents/Nav/HomeNav'
import ProjectManagement from './HomeComponents/ProjectManagementSection/ProjectManagement'
import SupportHome from './Support'
import HomeLink from './HomeComponents/HomeLink/HomeLink'
import ProjectLink from './HomeComponents/cta/ProjectLink'
import HomeContact from './HomeComponents/HomeContact/HomeContact'
const Home = () => {

  return (
    <div className='home__page'>
      <Nav />
      <Header/>
      <FeatureInfoSection />
      <ProjectLink />
      <HomeLink />
      <ProjectManagement />
      <HomeContact />
      <SupportHome />
      <Footer />
    </div>
  )
}

export default Home