import React, {  useState, useRef, useEffect } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { RiCloseLine, RiMenu3Line, } from 'react-icons/ri'
import './HomeNav.css' 
import logo from './logo.png'
const HomeNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [viewFeatures, setViewFeatures] = useState(false);
  const featureRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewFeatures && // Check if viewFeatures is true
        featureRef.current && // Check if featureRef is defined
        !featureRef.current.contains(event.target) && // Click is outside of featureRef
        event.target.textContent !== 'Features' // Click is not on "Features" text
      ) {
        setViewFeatures(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [viewFeatures]);


  return (
    <header className='msl__nav__header' style={{height: `${viewFeatures ? '100vh' : 'unset'}` }}>
    <nav className='siteHub__nav'>

      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <a href="/mslDecorating">
            <img src={logo} alt="msl logo" />
          </a>
        </div>
      </div>

        <div className='gpt3__navbar-links_container'>
          <p><a className='span__hover' href="/mslDecorating">Home</a></p>
          <p><a className='span__hover' href="/mslDecorating">About Us</a></p>
          <p><a className='span__hover' href="/mslDecorating">Services</a></p>
          <p><a className='span__hover' href="/mslDecorating">Projects</a></p>
        </div>
      <div className='msl__navbar-sign'>
        <a href="/contact">
          <button  type='button'>Contact</button>
        </a>
        <div className="toggle__menu">

        {toggleMenu
          ? <RiCloseLine style={{color: '#FFF'}}  size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line style={{color: '#FFF'}} size={27} onClick={() => setToggleMenu(true)} />
        }
        </div>
      </div>
    </nav>
        {toggleMenu && (
          <div className='siteHub__navbar-menu '>
            <div className='siteHub__navbar-menu_container scale-up-center'>
              <p><a className='span__hover' href="/mslDecorating">Home</a></p>
              <p><a className='span__hover' href="/mslDecorating">About Us</a></p>
              <p><a className='span__hover' href="/mslDecorating">Services</a></p>
              <p><a className='span__hover' href="/mslDecorating">Projects</a></p>
            </div>
            <div className='siteHUb-menu_container-links-sign nnpp scale-up-center'>
            <a href="/mslDecorating">
                <p>Contact Us</p>
                <button type='button'><FaLongArrowAltRight /></button>
              </a>
            </div>
          </div>
        )}


    </header>
  )
}

export default HomeNav