import React from 'react'
import './ProjectManagement.css'
import projectSiteView from '../../Assets/winWork.png'

const ProjectManagement = () => {
  return (
    <div className='project__management--home' id='features'>
      <div className='project__management--home-text'>
      <h2>Unlock Endless Possibilities with a Flood of New Clients</h2>
      <p>Partnering with a marketing agency dedicated to lead generation can drastically elevate your client acquisition rate. Experience a surge in qualified leads as we implement proven strategies tailored to your business, turning prospects into loyal clients. Our expertise in targeting and engaging the right audience ensures not just quantity, but quality in every lead, providing you with the best opportunities for conversion and growth.</p>
      </div>
      <div className='project__management--home-image'>
        <img src={projectSiteView} alt="project management" srcSet="" loading="lazy" />
      </div>
    </div>
  )
}

export default ProjectManagement
