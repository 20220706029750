import  './Footer.css'
import logo from './logo.png'
const Footer = () => {
  return (
    <footer>
      <div className='footer__logo'>
        <img src={logo} alt="" />
      </div>
      <div className='footer__links'>
        <h3>Company information</h3>
        <p>MSL Decorating</p>
        <p>Address</p>
        <p>Post code</p>
        <p>info@msldecorating.com</p>
        <p>012345123456</p>
      </div>
      <div className='footer__links'>
        <h3>Links</h3>
        <p>Contact</p>
        <p>Instagram</p>
        <p>Facebook</p>
      </div>
    </footer>
  )
}

export default Footer