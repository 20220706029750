import './InfoSec.css'
import { FaClock, FaRunning, FaSmile } from 'react-icons/fa'

const InfoSec = () => {
  return (
    <div className='info__sec'>
      <div className='service__links'>
        <h2>What we do</h2>
        <p>We present a team of adept professionals, each highly skilled and meticulously trained in their craft. While our inception was rooted in decorating, we have proudly expanded our suite of offerings to encompass a variety of specialized services, catering to both residential and commercial clients with unmatched proficiency and care.</p>
        <a href="/mslDecorating">
          <button>
            View Services
          </button>
        </a>
      </div>
      <div className="info__features">
        <div className="service__feature">
          <span><FaSmile/></span>
          <div>
          <h2>Client-Centric Approach</h2>
          <p>From your initial interaction with MSL Decorators, exceptional service is our pledge. Every client is pivotal, with their unique needs and specifications shaping our process. Whether you have inquiries or need to alter specifications, our responsive team is at your service at all times.</p>
          </div>
        </div>
        <div className="service__feature">
          <span><FaClock/></span>
          <div>
          <h2>Punctual Excellence</h2>
          <p>At MSL Decorators, punctuality is as crucial as the quality we deliver. Understanding our clients’ tight schedules, we not only promise impeccable results but also timely completion, allowing you to reclaim and relish your refreshed spaces precisely when expected.</p>
          </div>
        </div>
        <div className="service__feature">
          <span><FaRunning/></span>
          <div>
          <h2>Going Above & Beyond</h2>
          <p>Passion drives our endeavors at MSL Decorators, prompting us to consistently exceed expectations. This is evident in our customer service and the profound industry insight we employ to not just meet but surpass your expectations, delivering a service experience like no other.</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default InfoSec