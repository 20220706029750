import React from 'react'
import { FaPaintBrush, FaBullhorn, FaSearchDollar, FaUsers, FaLongArrowAltRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './HomeLink.css'

const HomeLink = () => {
  return (
    <div className='home__link'>
      <div className="home__link__left center__flex">
        <h2>siteHUB Advanced Marketing Solutions</h2>
        <p>Unveil a broad array of tools meticulously crafted to amplify your brand presence, generate leads, and foster enduring customer relationships.</p>
        <a href="/contact">Explore More <FaLongArrowAltRight/></a>
      </div>
      <div className="home__link__right">
        <div className="home__link__option span__hover">
          <FaPaintBrush style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
          <div>
            <h3>Web Design & Development <FaLongArrowAltRight/></h3>
            <p>Transform your digital presence with captivating designs and robust functionalities, creating an engaging user experience that converts.</p>
          </div>
        </div>
        <div className="home__link__option span__hover">
          <FaBullhorn style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
          <div>
            <h3>Strategic Advertising <FaLongArrowAltRight/></h3>
            <p>Deploy powerful advertising campaigns designed to resonate with your target audience, driving brand awareness and generating leads effectively.</p>
          </div>
        </div>
        <div className="home__link__option span__hover">
          <FaSearchDollar style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
          <div>
            <h3>SEO & SEM Services <FaLongArrowAltRight/></h3>
            <p>Dominate search engine rankings and attract high-quality traffic with our advanced SEO and SEM strategies tailored to your business needs.</p>
          </div>
        </div>
        <div className="home__link__option span__hover">
          <FaUsers style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
          <div>
            <h3>Client Relationship Management <FaLongArrowAltRight/></h3>
            <p>Strengthen and streamline your interactions with clients using our CRM tools, ensuring satisfaction and loyalty for sustained business growth.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeLink;
